import { Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { m } from 'framer-motion'
import { DoubleRightOutlined } from '@ant-design/icons'
import Menu from '../webpart/Menu';

export default function Intro() {
    const navigate = useNavigate()
    const flashingAnimation = {
        opacity: [0, 1, 0],
        transition: { repeat: Infinity, duration: 3 }
    };
    const homeTour = localStorage.getItem('homeTour')

    return (
        <>
            <div style={{ width: '100vw', height: '100vh' }}>
                <Menu />

                <div style={{
                    position: 'absolute',
                    top: '20%',
                    left: '5%',
                    width: '90%',
                    zIndex: '2'
                }}>
                    <m.h1 style={{
                        color: 'white',
                        fontSize: '4vw', // Responsive font size
                        textShadow: '2px 2px black',
                        width: '45%',
                    }}>
                        Unlock The World's Culture in Victoria
                    </m.h1><m.p style={{
                        color: 'white',
                        fontSize: '2vw',
                        textShadow: '1px 1px black'
                    }}>
                        Dive into diverse culture. Enhance your culture knowledge and connect the people.
                    </m.p>
                    <br />
                    <m.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                        style={{
                            width: '15vw',
                            height: 60,
                            zIndex: '2',
                        }}
                    >

                        {homeTour ?
                            <Button type="primary"
                                onClick={() => navigate('/games')}
                                style={{
                                    fontSize: '1.5vw', // Responsive font size
                                    height: '100%',
                                    width: '100%'
                                }}>
                                Begin The Tour!
                            </Button>
                            :
                            <a href="#visualisation" style={{ color: 'white' }}>
                                <Button type="primary"
                                    style={{
                                        fontSize: '1.5vw', // Responsive font size
                                        height: '100%',
                                        width: '100%'
                                    }}>
                                    Explore The Tour!
                                </Button>
                            </a>
                        }
                    </m.div>
                </div></div>
            <m.div
                initial={{ opacity: 0 }}
                animate={flashingAnimation}
                style={{
                    position: 'absolute',
                    top: '90%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: '2',
                    color: 'white',
                    fontSize: '1.5em',
                    textAlign: 'center'
                }}
            >
                {homeTour &&
                    <a href="#visualisation" style={{ color: 'white', textDecoration: 'none' }}>
                        <div style={{ fontSize: 18 }}>More About Cultural Diversity</div>
                        <DoubleRightOutlined rotate={90} style={{
                            fontSize: 50
                        }} /></a>
                }
            </m.div>
        </>
    )
}
