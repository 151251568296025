import React, { useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { Col, Row, Spin, Tour } from 'antd'
import axios from 'axios'
import './LanguageOngoingMain.css'
import LanguageGameBtn from '../webpart/LanguageGameBtn'
import { useNavigate } from 'react-router-dom'

export default function LanguageOngoingMain(props) {
    const navigate = useNavigate()
    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const { country } = props
    const [playLoading, setPlayLoading] = useState(false)
    const [gameStatus, setGameStatus] = useState(0)
    const [currentScene, setCurrentScene] = useState({})
    const [optionShow, setOptionShow] = useState(false)
    const [question, setQuestion] = useState({})
    const [options, setOptions] = useState([])
    const [gameFailed, setGameFailed] = useState(false)
    const [gameSuccess, setGameSuccess] = useState(false)
    const [wordMeaning, setWordMeaning] = useState({})
    const [tour1Open, setTour1Open] = useState(false)
    const [tour2Open, setTour2Open] = useState(false)
    const languageTourOpen = localStorage.getItem('languageTourOpen')
    const sceneRequestURL = `https://9f8rfvs6cd.execute-api.ap-southeast-2.amazonaws.com/explore/api/games/scene?country=${country}&question_id=`
    const questionRequestURL = `https://9f8rfvs6cd.execute-api.ap-southeast-2.amazonaws.com/explore/api/games/language?country=${country}&question_id=`
    const steps1 = [
        {
            title: 'Watch the video',
            description: 'Watch the video to view the story',
            target: () => ref1.current,
        }
    ]
    const steps2 = [
        {
            title: 'Read the question',
            description: 'Read the question associated with the scene',
            target: () => ref2.current,
        },
        {
            title: 'Answer the question',
            description: 'Select one of the options, only ONE correct answer can go to next scene',
            target: () => ref3.current,
        }
    ]

    const loadStory = () => {
        setPlayLoading(true)
        const sceneRequest = axios.get(sceneRequestURL + 0)

        sceneRequest.then(res => {
            const sceneResponse = res.data[0]
            setCurrentScene(sceneResponse)
            setPlayLoading(false)
            setGameStatus(1)
        }).catch(err => {
            console.log('An error occurred: ', err)
        })
    }

    const handleOption = () => {
        if (currentScene.type === 0 && !languageTourOpen) {
            setTour1Open(true)
        }
        else if (currentScene.type === 2) {
            setOptionShow(false)
            setGameFailed(true)
            setPlayLoading(false)
        } else if (currentScene.type === 3) {
            setOptionShow(false)
            setGameSuccess(true)
            setPlayLoading(false)
        } else {
            if (currentScene.type === 1 && !languageTourOpen) {
                setTimeout(() => {
                    setTour2Open(true)
                    localStorage.setItem('languageTourOpen', true)
                }, 1000)
            }
            setOptionShow(true)

        }
    }

    const handleNext = (index) => {
        setPlayLoading(true)
        axios.get(sceneRequestURL + currentScene.next_scene[index]).then(res => {
            setGameFailed(false)
            setCurrentScene(res.data[0])
            setOptionShow(false)
            if (res.data[0]?.type === 1) {
                getQuestion(res.data[0].question_id)
            }
        }).catch(err => {
            console.log('An error occurred: ', err)
        })
    }

    const getQuestion = (id) => {
        axios.get(questionRequestURL + id).then(res => {
            setQuestion(res.data)
            setOptions(res.data.options[0])
            setPlayLoading(false)
        }).catch(err => {
            console.log('An error occurred: ', err)
        })
    }

    const handleType1 = (index) => {
        if (question.correct_answer_id === index) {
            handleNext(0)
        } else {
            setWordMeaning(options[index])
            handleNext(1)
        }
    }

    return (
        <>
            {
                gameStatus === 0 &&
                <motion.div style={{ width: '100vw', height: '100vh' }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, ease: "easeOut" }}>
                    <img
                        src={country === 'Australia' ? '/language-game/cinderia.jpg' :
                            country === 'England' ? '/language-game/wizard.jpg' :
                                '/language-game/little-red.jpg'} alt='game-start' style={{ width: '100vw', height: '100vh', position: 'fixed' }} />
                    <div style={{ position: 'fixed', width: country === 'Australia' ? '70%' : '100%', textAlign: 'center', top: country === 'Australia' ? '40vh' : country === 'England' ? '45vh' : '25vh', right: country === 'Australia' && 0 }}>
                        <LanguageGameBtn height={150} width={350} fontSize='4em' text='PLAY' handleClick={loadStory} playLoading={playLoading} />
                    </div>
                </motion.div >
            }
            {
                gameStatus === 1 && currentScene &&
                <>
                    <motion.video
                        autoPlay
                        ref={ref1}
                        muted
                        style={{
                            width: '100vw',
                            height: '100vh',
                            objectFit: 'fill',
                            position: 'fixed',
                            zIndex: 0,
                            top: 0,
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1, ease: "easeOut" }}
                        src={currentScene.url}
                        onEnded={handleOption}
                    />
                    {optionShow &&
                        <>
                            {currentScene.type === 0 &&
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ duration: 0.5, ease: "easeOut" }}
                                    className='option-background'
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                    <LanguageGameBtn height={150} width={350} fontSize='2em' text='CONTINUE...' handleClick={() => handleNext(0)} playLoading={playLoading} />
                                </motion.div>
                            }
                            {
                                currentScene.type === 1 &&
                                <>
                                    <motion.div
                                        initial={{ y: 50, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        transition={{ duration: 0.5, ease: "easeOut" }}
                                        className='option-background'>
                                        <div ref={ref2} style={{ color: 'white', fontFamily: 'Georgia', paddingInlineStart: '2.5%', fontSize: '2vw', fontWeight: 'bold', textShadow: '1px 2px black', position: 'absolute', bottom: '100%', width: '100%', background: 'linear-gradient(to top, rgb(0,0,0,0.7), transparent)' }}>{question.question_detail}</div>
                                        <Row style={{ width: '100%', height: '90%', position: 'absolute', bottom: '10%' }} ref={ref3}>
                                            {
                                                options.length > 0 ?
                                                    options.map((option, index) => (
                                                        <Col key={index} span={12} className='option-background-col'>
                                                            <LanguageGameBtn height='80%' width='90%' fontSize='2em' text={country === 'Australia' || country === 'England' ? option.option : getOptionText(country, option)}
                                                                handleClick={() => handleType1(index)} playLoading={playLoading} />
                                                        </Col>
                                                    ))
                                                    :
                                                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Spin size="large" />
                                                    </div>
                                            }

                                        </Row>
                                    </motion.div>
                                </>
                            }
                        </>
                    }
                    {gameFailed &&
                        <motion.div initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5, ease: "easeOut" }}
                            style={{ height: '100vh', width: '100vw', position: 'fixed', top: 0, background: 'rgb(0,0,0,0.7)' }}>

                            <div style={{ height: '80vh', width: '80vw', background: '#dcd2b6', border: '10px solid #B8B1A0', borderRadius: 18, zIndex: 1000, position: 'absolute', top: '10vh', left: '10vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <motion.div
                                    initial={{ y: -20, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                    style={{ width: '80%', height: '80%', textAlign: 'center' }}>
                                    <img src='/language-game/game-over.png' alt='game-over' style={{ height: '40%', marginTop: '5%' }} />
                                    <h2 style={{ fontFamily: 'Georgia' }}><span style={{ color: 'red' }}>{country === 'Australia' || country === 'England' ? wordMeaning.option : getOptionText(country, wordMeaning)}: </span>{wordMeaning.meaning}</h2>

                                    <Row style={{ marginTop: '10%' }}>
                                        <Col span={12} className='summary-footer-col'>
                                            <LanguageGameBtn height='100%' width='60%' fontSize='1.5vw' text='TRY AGAIN' handleClick={() => handleNext(0)} playLoading={playLoading} />
                                        </Col>
                                        <Col span={12} className='summary-footer-col'>
                                            <LanguageGameBtn height='100%' width='60%' fontSize='1.5vw' text='BACK TO MENU' handleClick={() => navigate(`/games/game-menu?country=${country}`)} playLoading={playLoading} />
                                        </Col>
                                    </Row>
                                </motion.div>
                            </div>
                        </motion.div>

                    }
                    {
                        gameSuccess &&
                        <motion.div initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5, ease: "easeOut" }}
                            style={{ height: '100vh', width: '100vw', position: 'fixed', top: 0, background: 'rgb(0,0,0,0.7)' }}>

                            <div style={{ height: '80vh', width: '80vw', background: '#dcd2b6', border: '10px solid #B8B1A0', borderRadius: 18, zIndex: 1000, position: 'absolute', top: '10vh', left: '10vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <motion.div
                                    initial={{ y: -20, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                    style={{ width: '80%', height: '80%', textAlign: 'center' }}>
                                    <img src='/game-icon/summary-icon/gold.png' alt='game-win' style={{ height: '40%', marginTop: '5%' }} />
                                    <h2 style={{ color: '#ff6419', fontFamily: 'Georgia' }}>Congratulations! You have passed this game!</h2>

                                    <Row style={{ marginTop: '10%' }}>
                                        <Col span={12} className='summary-footer-col'>
                                            <LanguageGameBtn height='100%' width='60%' fontSize='1.5vw' text='PLAY AGAIN' handleClick={() => navigate(0)} playLoading={playLoading} />
                                        </Col>
                                        <Col span={12} className='summary-footer-col'>
                                            <LanguageGameBtn height='100%' width='60%' fontSize='1.5vw' text='BACK TO MENU' handleClick={() => navigate(`/games/game-menu?country=${country}`)} playLoading={playLoading} />
                                        </Col>
                                    </Row>
                                </motion.div>
                            </div>
                        </motion.div>
                    }
                    <Tour open={tour1Open} onClose={() => {
                        setTour1Open(false)
                        setOptionShow(true)
                    }} steps={steps1} />
                    <Tour open={tour2Open} onClose={() => setTour2Open(false)} steps={steps2} />
                </>
            }
        </>
    )
}

function getOptionText(country, option) {
    switch (country) {
        case 'China':
            return option.option_china;
        case 'India':
            return option.option_india;
        case 'Vietnam':
            return option.option_vietnam;
        case 'Thailand':
            return option.option_thailand;
        default:
            return 'Unknown Option';
    }
}
