import React, { useEffect, useRef, useState } from 'react';
import './Library.css';
import { Button, Col, Row, Spin, Tooltip, Tour, message } from 'antd';
import ReactiveButton from 'reactive-button';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion'

export default function Library(props) {
    const navigate = useNavigate()
    const [showMore, setShowMore] = useState(false)
    const [bookContent, setBookContent] = useState([])
    const [typeSelect, setTypeSelect] = useState(null)
    const [detailSelect, setDetailSelect] = useState(null)
    const { country } = props;
    const [btnLoading, setBtnLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const typeBtnStyle = {
        height: 50,
        width: 150
    }

    useEffect(() => {
        if (!['China', 'England', 'India', 'Australia', 'Thailand', 'Vietnam'].includes(country)) {
            navigate('/page-error')
        }
        // Reset content on country change
        setTypeSelect(null);
        setBookContent([]);
        setDetailSelect(null);
    }, [country, navigate])

    const handleType = (type) => {
        setBtnLoading(true)
        axios.get(`https://9f8rfvs6cd.execute-api.ap-southeast-2.amazonaws.com/explore/api/library/${type}?country=${country}`).then(res => {
            setBookContent(res.data)
            setTypeSelect(type)
            setBtnLoading(false)
        }).catch(() => {
            setBtnLoading(false)
            messageApi.error('loading error, redirect to country selection...')
            setTimeout(() => {
                navigate('/games/game-initial?mode=library')
            }, 2000)
        })
    }


    return (
        <>
            {contextHolder}
            {detailSelect == null ?
                <>
                    <Row style={{ width: '100%' }} gutter={40}>
                        <Col span={3}>
                            <img src='/library/admin.png' alt='admin' style={{ width: 100, position: 'absolute', right: 40 }} />
                        </Col>
                        <Col span={21} className='library-intro'>
                            {country === 'Australia' && <>Hello! </>}
                            {country === 'China' && <>你好！</>}
                            {country === 'England' && <>Hello! </>}
                            {country === 'India' && <>नमस्ते！</>}
                            {country === 'Thailand' && <>नสวัสดี！</>}
                            {country === 'Vietnam' && <>Xin chào! </>}
                            Welcome to VICulture Playground Library Station! I'm glad to help with you! What kind of culture you want to explore in {country}?
                            {!typeSelect && <Row style={{ width: '100%', height: 120 }} gutter={30}>
                                <Col className='type-col' span={8}>
                                    <motion.div initial={{ y: 20, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        transition={{ duration: 0.5 }} >
                                        <ReactiveButton rounded idleText='Famous Places' buttonState={btnLoading ? 'loading' : 'idle'} loadingText={'loading...'} style={typeBtnStyle} onClick={() => handleType('attraction')} />
                                    </motion.div>
                                </Col>
                                <Col className='type-col' span={8}>
                                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3, duration: 0.5 }}>
                                        <ReactiveButton rounded idleText='Food' buttonState={btnLoading ? 'loading' : 'idle'} loadingText={'loading...'} style={typeBtnStyle} onClick={() => handleType('food')} />
                                    </motion.div>
                                </Col>
                                <Col className='type-col' span={8}>
                                    <Tooltip color='#E9800F' title='Under construction...'>
                                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.6, duration: 0.5 }}>
                                            <ReactiveButton rounded disabled idleText='Language' style={typeBtnStyle} />
                                        </motion.div>
                                    </Tooltip>
                                </Col>
                            </Row>}
                        </Col >
                    </Row >
                    {typeSelect &&
                        <>
                            <Row style={{ width: '100%', marginTop: '2%' }} gutter={40}>
                                <Col span={21} className='library-intro' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    {typeSelect === 'attraction' && 'Famous Places'}
                                    {typeSelect === 'food' && 'Food'}
                                    {typeSelect === 'language' && 'Language'}
                                </Col>
                                <Col span={3}>
                                    <img src='/library/user.png' alt='user' style={{ width: 100 }} />
                                </Col>
                            </Row>
                            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5, duration: 0.5 }}>
                                <Row style={{ width: '100%', marginTop: '2%' }} gutter={40}>
                                    <Col span={3}>
                                        <img src='/library/admin.png' alt='admin' style={{ width: 100, position: 'absolute', right: 40 }} />
                                    </Col>
                                    <Col span={21} className='library-intro'>
                                        Good! And what {typeSelect === 'attraction' ? 'famous places' : typeSelect} do you want to explore?
                                        {bookContent.length > 0 &&
                                            <Row style={{ width: '100%', height: 150, marginBottom: 50 }} gutter={30}>
                                                {typeSelect === 'attraction' && bookContent.map((content, index) =>
                                                    index % 2 === 0 &&
                                                    <Col className='type-col' key={index} span={8}>
                                                        <motion.div initial={{ y: 20, opacity: 0 }}
                                                            animate={{ y: 0, opacity: 1 }}
                                                            transition={{ duration: 0.5 }} >
                                                            <ReactiveButton rounded idleText={content.header} style={typeBtnStyle} onClick={() => {
                                                                setDetailSelect(content.id)
                                                            }} />
                                                        </motion.div>
                                                    </Col>
                                                )}
                                                {typeSelect === 'food' && bookContent.map((content, index) => {
                                                    return ( // This return statement is necessary
                                                        <Col className='type-col' key={content.id} span={8}>
                                                            <motion.div initial={{ y: 20, opacity: 0 }}
                                                                animate={{ y: 0, opacity: 1 }}
                                                                transition={{ duration: 0.5 }} >
                                                                <ReactiveButton rounded idleText={content.name} style={typeBtnStyle} onClick={() => {
                                                                    setDetailSelect(index)
                                                                }} />
                                                            </motion.div>
                                                        </Col>
                                                    );
                                                })}
                                                <Col className='type-col' span={8}>
                                                    <motion.div initial={{ y: 20, opacity: 0 }}
                                                        animate={{ y: 0, opacity: 1 }}
                                                        transition={{ duration: 0.5 }} >
                                                        <ReactiveButton rounded outline idleText='Back' style={typeBtnStyle} onClick={() => {
                                                            setTypeSelect(!typeSelect)
                                                        }} />
                                                    </motion.div>
                                                </Col>
                                            </Row>}
                                    </Col >
                                </Row >
                            </motion.div >
                        </>
                    }
                </>
                :
                <>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5 }}
                        style={{ width: '100%' }}
                        className='book-content'
                    >
                        <Row style={{ width: '100%' }} gutter={40}>
                            <Col span={3}>
                                <img src='/library/admin.png' alt='admin' style={{ width: '50%' }} />
                            </Col>
                            <Col span={21} className='library-intro'>
                                Here's the information about {typeSelect === 'attraction' && bookContent[detailSelect]?.header}
                                {typeSelect === 'food' && bookContent[detailSelect]?.name}:
                                <Row gutter={30}>
                                    <Col span={10} style={{ height: '100%' }}>
                                        {typeSelect === 'attraction' && <img src={bookContent[detailSelect]?.image_url} alt={bookContent[detailSelect]?.header} style={{ width: '100%', borderRadius: 18, height: 200 }} />}
                                        {typeSelect === 'food' && <img src={bookContent[detailSelect]?.image_url} alt={bookContent[detailSelect]?.name} style={{ width: '100%', borderRadius: 18, height: 200 }} />}
                                    </Col>
                                    <Col span={14} style={{ fontSize: '1vw' }}>{typeSelect === 'attraction' && bookContent[detailSelect]?.detail}
                                        {typeSelect === 'food' && bookContent[detailSelect]?.description}</Col>
                                </Row>
                                {showMore &&
                                    <motion.div
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -20 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        <Row gutter={30} style={{ marginTop: '2%', height: '100%' }} className='show-more' >
                                            <Col span={14} style={{ fontSize: '1vw' }}>{bookContent[detailSelect + 1]?.detail}</Col>
                                            <Col span={10}>
                                                <img src={bookContent[detailSelect + 1]?.image_url} alt={bookContent[detailSelect + 1]?.header} style={{ width: '100%', borderRadius: 18, height: 200 }} />
                                            </Col>
                                        </Row>
                                    </motion.div>
                                }
                                <Row style={{ width: '100%', height: 120, marginTop: 20 }} gutter={30}>
                                    <Col className='type-col' span={12}>
                                        {typeSelect === 'attraction' &&
                                            <motion.div initial={{ y: 20, opacity: 0 }}
                                                animate={{ y: 0, opacity: 1 }}
                                                transition={{ duration: 0.5 }} >
                                                <ReactiveButton rounded idleText={showMore ? 'Hide...' : 'Show More...'} style={typeBtnStyle} onClick={() => setShowMore(!showMore)} />
                                            </motion.div>
                                        }
                                    </Col>
                                    <Col className='type-col' span={typeSelect === 'attraction' ? 12 : 24}>
                                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3, duration: 0.5 }}>
                                            <ReactiveButton outline rounded idleText='Back' style={typeBtnStyle} onClick={() => {
                                                setShowMore(false)
                                                setTypeSelect(false)
                                                setDetailSelect(null)
                                            }} />
                                        </motion.div>
                                    </Col>
                                </Row>
                            </Col >
                        </Row >
                    </motion.div>
                </>
            }
        </>
    );
}
