import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from '../components/home/Home';
import GameLayout from '../components/game-layout/GameLayout';
import GameStart from '../components/game-start/GameStart';
import GameInitial from '../components/game-start/GameInitial';
import GameMenu from '../components/game-menu/GameMenu';
import GameOngoingLayout from '../components/game-layout/GameOngoingLayout'
import FestivalCalendar from '../components/calendar/FestivalCalendar';
import ErrorPage from '../components/error-page/ErrorPage';
import Demographic from '../components/about/Demographic';
import Attribution from '../components/about/Attribution';

export default function IndexRouter() {
    return (
        <Routes>
            <Route index element={<Home />} />
            <Route path='games' element={<GameLayout />}>
                <Route index element={<GameStart />} />
                <Route path='game-initial' element={<GameInitial />} />
                <Route path='game-menu' element={<GameMenu />} />
                <Route path='*' element={<Navigate to="/page-error" replace />} />
            </Route>
            <Route path='game-ongoing' element={<GameOngoingLayout />} />
            <Route path='page-error' element={<ErrorPage />} />
            <Route path='library' element={<GameOngoingLayout />} />
            <Route path='calendar' element={<FestivalCalendar />} />
            <Route path='demographic' element={<Demographic />} />
            <Route path='attribution' element={<Attribution />} />
            <Route path='*' element={<Navigate to="/page-error" replace />} />
        </Routes>
    );
}
