import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { Row, Col } from 'antd'

const ModeSection = ({ children }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    return (
        <section ref={ref} style={{ marginTop: 400 }}>
            <span
                style={{
                    transform: isInView ? "none" : "translateY(200px)",
                    opacity: isInView ? 1 : 0,
                    transition: "all 1.2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}
            >
                {children}
            </span>
        </section>
    );
}

export default function SectionContent() {
    return <>
        <ModeSection>
            <Row gutter={30}>
                <Col span={12} className="home-mode-section-image">
                    <img src="/home-mode/cultural-diversity.jpg" alt="cultural-diversity" />
                </Col>
                <Col span={12} className="home-mode-section-content">
                    <div style={{ fontSize: '3vw', fontWeight: 'bold' }}>Multiple cultures in Victoria</div>
                    <div style={{ fontSize: '2vw', marginTop: 30 }}>Provide the best interactive methods to let you appreciate the culture of 6 popular countries, including <span style={{ color: '#EFB00A', fontWeight: 'bold' }}>Australia, China, England,</span> and more.</div>
                </Col>
            </Row>
        </ModeSection>
        <ModeSection>
            <div style={{ marginBottom: 50 }}>
                <div style={{ fontSize: '3vw', fontWeight: 'bold' }}>
                    Different Exploration Mode
                </div>
                <div style={{ fontSize: '2vw' }}>
                    Explore diverse cultures in multiple ways.
                </div>
            </div>
            <Row gutter={100}>
                <Col span={12}>
                    <div className="home-mode-section-content" style={{ marginBottom: 50 }}>
                        <h1 style={{ color: '#EFB00A' }}>Cultural Quiz Game</h1>
                        <p>Learn about different cultures by answering interesting quizzes.</p>
                    </div>
                    <div className="home-mode-section-image">
                        <img src="/home-mode/quiz-game.jpg" alt="quiz-game" />
                    </div>
                </Col>
                <Col span={12}>
                    <div className="home-mode-section-image">
                        <img src="/home-mode/cultural-library.jpg" alt="cultural-library" />
                    </div>
                    <div className="home-mode-section-content" style={{ marginTop: 50 }}>
                        <h1 style={{ color: '#EFB00A' }}>Cultural Library Station</h1>
                        <p>Find the cultural knowledge you want in books.</p>
                    </div>
                </Col>
            </Row>
        </ModeSection>
        <ModeSection>
            <Row gutter={30}>
                <Col span={12} className="home-mode-section-content">
                    <div style={{ fontSize: '3vw', fontWeight: 'bold' }}>Cultural Calendar</div>
                    <div style={{ fontSize: '2vw', marginTop: 30 }}>Learn about <span style={{ color: '#EFB00A', fontWeight: 'bold' }}>important festivals</span> in different countries’ cultures.</div>
                </Col>
                <Col span={12} className="home-mode-section-image">
                    <img src="/home-mode/calendar.jpg" alt="calendar" />
                </Col>
            </Row>
        </ModeSection>
    </>
}