import React, { useEffect } from 'react'
import HomeBackground from '../webpart/HomeBackground'
import Menu from '../webpart/Menu'
import Footer from '../home/Footer'
import FloatBtn from '../webpart/FloatBtn'
import { List } from 'antd'
import { Link } from 'react-router-dom'

export default function Attribution() {
    const imageList = [
        {
            name: 'Matthew Paul ArgallFollow,”Milo” , 2019, licensed under CC BY 2.0.',
            src: 'https://www.flickr.com/photos/155643540@N07/46553594095'
        },
        {
            name: '. Entrer dans le rêve, “Mì Quảng.”, 2010, licensed under CC BY 2.0.',
            src: 'https://www.flickr.com/photos/tranbina/5088139012'
        },
        {
            name: 'Jorge Láscar, "Wat Phra That Doi Suthep", 2019, licensed under CC BY 2.0.',
            src: 'https://www.flickr.com/photos/tranbina/5088139012'
        }
    ]
    const iconList = [
        { href: "https://www.flaticon.com/free-icons/gaming", title: "gaming icons", creator: "Flat Icons" },
        { href: "https://www.flaticon.com/free-icons/school", title: "school icons", creator: "Freepik" },
        { href: "https://www.flaticon.com/free-icons/home-button", title: "home button icons", creator: "Freepik" },
        { href: "https://www.flaticon.com/free-icons/unlink", title: "unlink icons", creator: "Andy Horvath" },
        { href: "https://www.flaticon.com/free-icons/trophy", title: "trophy icons", creator: "Freepik" },
        { href: "https://www.flaticon.com/free-icons/smile", title: "smile icons", creator: "Illosalz" },
        { href: "https://www.flaticon.com/free-icons/user", title: "user icons", creator: "Freepik" },
        { href: "https://www.flaticon.com/free-icons/user", title: "user icons", creator: "Smashicons" },
        { href: "https://www.flaticon.com/free-icons/game-over", title: "game over icons", creator: "Freepik" },
        { href: "https://www.flaticon.com/free-icons/australia", title: "australia icons", creator: "SBTS2018" },
        { href: "https://www.flaticon.com/free-icons/taj-mahal", title: "taj mahal icons", creator: "Freepik" },
        { href: "https://www.flaticon.com/free-icons/england", title: "england icons", creator: "Freepik" },
        { href: "https://www.flaticon.com/free-icons/vietnamese", title: "vietnamese icons", creator: "Freepik" },
        { href: "https://www.flaticon.com/free-icons/temple", title: "temple icons", creator: "Freepik" },
        { href: "https://www.flaticon.com/free-icons/great-wall-of-china", title: "great wall of china icons", creator: "Freepik" }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HomeBackground />
            <Menu />
            <div style={{ height: 200 }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: '3vw',
                    fontWeight: 'bold',
                }}>
                    <div style={{ width: '40%', textAlign: 'center', background: 'rgb(0,0,0,0.5)', borderRadius: 18, color: 'rgb(239, 176, 10)' }}>Attribution</div></div>
            </div>
            <div className='demographic-head'>
                <div>
                    Thanks to the following authors for their support of VICulture Playground, including <span className='highlight-word'>Images</span> and <span className='highlight-word'>Icons</span>.
                </div>
            </div>
            <div style={{ background: 'rgb(255, 255, 255, 0.8)' }}>
                <div style={{ width: '80vw', marginLeft: '10vw' }}>
                    <div>
                        <div style={{ color: '#2C2A25', fontWeight: 'bold', fontSize: '2vw' }}>Images</div>
                        <List size='small' dataSource={imageList} renderItem={(item) => <List.Item>{item.name} <Link to={item.src}>{item.src}</Link></List.Item>} />
                    </div>
                    <div>
                        <div style={{ color: '#2C2A25', fontWeight: 'bold', fontSize: '2vw' }}>Icons</div>
                        <List size='small' dataSource={iconList} renderItem={(link) => <List.Item><Link to={link.href} title={link.title}>{link.title} created by {link.creator} - Flaticon</Link></List.Item>} />
                    </div>
                </div>
            </div>
            <Footer />
            <FloatBtn />
        </>
    )
}
