import React from 'react';
import { Row, Col, Statistic, Button } from 'antd';
import { LazyMotion, m, domAnimation, useViewportScroll, useTransform } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import './Home.css'
import ModeDisplay from './ModeDisplay';
import OurAim from './OurAim';
import Footer from './Footer';
import CountUp from 'react-countup';
import HomeBackground from '../webpart/HomeBackground';
import Intro from './Intro';
import FloatBtn from '../webpart/FloatBtn';
const formatter = (value) => <CountUp end={value} duration={4} />;


export default function Home() {
  const navigate = useNavigate()

  const { scrollY } = useViewportScroll();
  const scale = useTransform(scrollY, [0, 1000], [1, 1.5]);

  return (
    <LazyMotion features={domAnimation}>
      <div>
        <HomeBackground />
        <m.div initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          id='home-introduction'>
          <Intro />


        </m.div>
        <m.div
          id='visualisation'
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          style={{
            width: '100%',
            background: 'linear-gradient(to bottom, transparent, black 80%)',
            height: 900
          }}>
          <div className='visualisation-container' style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <m.div
              style={{
                height: '500px',
                width: '50vw',
                borderRadius: '10px',
                scale,
                translateY: 90,
                marginTop: 100,
              }}
            >

              <div style={{ fontSize: '1.5vw', textAlign: 'center', color: '#EFB00A', width: '50%', background: 'rgb(0,0,0,0.5)', marginLeft: '25%', borderRadius: 10 }}>
                VICulture Playground is for...
              </div>
              <h1 style={{ fontSize: '3vw', fontWeight: 'bold', color: 'white', textAlign: 'center' }}>
                Discover Victoria’s Cultural Diversity
              </h1>
              <Row gutter={30} style={{ color: 'white' }}>
                <Col span={8} style={{ textAlign: 'center' }}>
                  <div style={{ fontSize: '1.5vw' }}>Today, the proportion of Victorians with English ancestry is</div>
                  <div>
                    <Statistic value={22.6} suffix="%" valueStyle={{
                      fontSize: '3vw', color: '#EFB00A',
                      fontWeight: 'bold'
                    }} formatter={formatter} />
                  </div>
                </Col>
                <Col span={8}>
                  <div>
                    <Statistic value={257993} valueStyle={{
                      fontSize: '3vw', color: '#EFB00A',
                      fontWeight: 'bold'
                    }} formatter={formatter} />
                  </div>
                  <div style={{ fontSize: '1.5vw' }}>people came to Victoria from India, becoming the largest number of immigrants</div>
                </Col>
                <Col span={8}>
                  <div style={{ fontSize: '1.5vw' }}>The largest proportion of international students come from China and India.</div>
                  <div style={{ marginTop: 30 }}>
                    <Button ghost onClick={() => navigate('/demographic')}>Explore your suburb diversity here...</Button>
                  </div>
                </Col>
              </Row>
            </m.div>
          </div>
        </m.div>
        <ModeDisplay />
        <OurAim />
        <Footer />
      </div>
      <FloatBtn />
    </LazyMotion>
  );
}
