import { Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Menu() {
    const navigate = useNavigate()
    return (
        <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: 'transparent',
            color: '#fff',
            position: 'relative',
            top: 0,
            zIndex: 2
        }}>
            <div style={{ height: 100, width: 200, padding: 30 }}><img src='/logo.jpg' alt='logo' style={{ height: '100%', width: '100%', cursor: 'pointer' }} onClick={() => navigate('/')} /></div>
            <div style={{ display: 'flex', gap: '20px', fontSize: '1.2em' }}>
                <Button type='text' className='home-menu' onClick={() => navigate('/')}>Home</Button>
                <Button type='text' className='home-menu' onClick={() => navigate('/demographic')}>Victorian Diversity</Button>
                {/* <Button type='text' className='home-menu' onClick={() => navigate('/calendar')}>Calendar</Button> */}
            </div>
        </div>
    )
}
